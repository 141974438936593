@import 'https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css';

.navbar-item {
  height: 40px;
  align-self: center;
}

.navbar-item-name {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 900;
}

.icon-link {
  color: inherit;
}

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.header {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-inline: 25px;
}

.section {
  flex: 1;
  padding-top: 0;
}

.footer {
  padding-bottom: 3rem;
}
